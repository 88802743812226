<template>
  <div class='payment'>
    <!-- <Header title="付款申请单申请"></Header> -->
    <van-row>
      <van-form>
        <van-cell-group title="基本信息" :border="true" size="large">
          <van-field v-model="paymentForm.no" label="申请编号" size='large' :border="true" readonly/>
          <van-field v-model="paymentForm.title" label="标题" size='large' :border="true" placeholder="请输入标题" />
          <van-field v-model="paymentForm.desc" label="付款详情" type="textarea" size='large' :border="true" placeholder="请输入付款详情" />
          <van-field v-model="paymentForm.contract_no" label="合同编号" size='large' :border="true" placeholder="请输入合同编号" />
          <van-field v-model="paymentForm.contract_url" label="合同链接" size='large' :border="true" placeholder="请输入合同链接" />
          <van-uploader style="margin:20px auto;">
            <van-button icon="plus" type="primary">上传文件</van-button>
          </van-uploader>
        </van-cell-group>
        <van-cell-group title="申请人信息" :border="true">
          <van-field v-model="paymentForm.user_name" label="姓名" size='large' :border="true" readonly />
          <van-field v-model="paymentForm.department" label="部门" size='large' placeholder="请选择所在部门" @click="department.show = true" readonly is-link/>
          <van-action-sheet v-model="department.show" :actions="department.options" @select="department_onselect" />
          <van-field v-model="paymentForm.position" label="职位" size='large' :border="true" readonly />
          
        </van-cell-group>
        <van-cell-group title="付款信息" :border="true">
          <van-field v-model="paymentForm.payer" label="付款单位" size='large' placeholder="请选择付款单位" @click="payer_show = true" readonly is-link/>
          <van-action-sheet v-model="payer_show" :actions="cost_centers" @select="payer_onselect" />
          <van-field v-model="paymentForm.payee" label="收款户名" size='large' :border="true" placeholder="请输入收款户名" />
          <van-field v-model="paymentForm.bank" label="开户银行" size='large' :border="true" placeholder="请输入开户银行" />
          <van-field v-model="paymentForm.bank_account" label="收款账号" size='large' :border="true" placeholder="请输入收款账号" />
          <van-field v-model="paymentForm.amount" label="付款金额" size='large' type="number" :border="true" placeholder="请输入付款金额" />
          <van-field v-model="paymentForm.payment_term" readonly center label="付款方式" size='large' :border="true" is-link @click="payment_term.show = true" placeholder="请选择付款方式" />
          <van-action-sheet v-model="payment_term.show" :actions="payment_term.options" @select="payment_term_onselect" />
          <van-field readonly clickable label="付款方式" :value="paymentForm.payment_term" placeholder="请选择付款方式" @click="payment_term.show = true"/>
          <van-popup v-model="payment_term.show" round position="bottom">
            <van-picker show-toolbar :columns="payment_term.options" @cancel="payment_term.show = false"   @confirm="payment_term_onselect" />
          </van-popup>
       

        </van-cell-group>
        <van-cell-group title="费用归集及分摊" :border="true">
          
        </van-cell-group>

        <van-cell-group :border="true" v-for="(item,i) in paymentForm.costs" :key="i">
          <van-row class="account_top">
            <van-col span="16">
              <span class="no">0{{i+1}}</span>
            </van-col>
            <van-col span="4"><van-button @click="delNode(i)" plain size="small" type="default" native-type="submit">- 删除</van-button></van-col>
            <van-col span="4"><van-button @click="addNode()" plain size="small" type="default" native-type="submit">+ 添加</van-button></van-col>
          </van-row>
          <van-field v-model="paymentForm.costs[i].cost_center" label="成本中心" size='large' :border="true" placeholder="请选择成本中心"  @click="cost_payer[i].show = true" readonly is-link/>
          <van-action-sheet v-model="cost_payer[i].show" :actions="cost_centers" @select="cost_payer_onselect($event,i)" />
          <van-field v-model="paymentForm.costs[i].cost_account" label="费用科目" size='large' :border="true" placeholder="请选择费用科目" @click="cost_account[i].show = true" readonly is-link/>
          <van-action-sheet v-model="cost_account[i].show" :actions="cost_accounts" @select="cost_account_onselect($event,i)" />
          <van-field v-model="paymentForm.costs[i].amount" label="分摊金额" size='large' :border="true" placeholder="请输入分摊金额" />
          <van-row >
            <van-col span="12" style="margin:10px auto ">计划预算：50000元</van-col>
            <van-col span="12" style="margin:10px auto">已用预算 30000元</van-col>
            <van-col span="12" style="margin:10px auto">可用预算：20000元</van-col>
          </van-row>

         </van-cell-group>
         <Approver :approvers='approvers'></Approver>
          
        
        
          
      
        <div style="margin: 20px 0 50px 0;">
          <van-row>
            <van-col span="12"><van-button type="primary" native-type="submit">保存草稿</van-button></van-col>
            <van-col span="12"><van-button  type="info" @click="payment_submit">提交审批</van-button></van-col>
          </van-row>
          
          
        </div>
      </van-form>
    </van-row>
  </div>
</template>

<script>
import Header from '../Header.vue'
import Approver from './Approver.vue'
export default {
  components: { Header,Approver},
  name: 'Payment',
  props: {
    
  },
  data(){
    return{
      payer_show:false,
      cost_centers:{},
      cost_account:{},
      showPicker:false,
      columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      payment_term:{
        show:false,
        options:['银行转账','现金','支票','承兑汇票'],
      },
      cost_payer:[{
        show:false
      }],
      cost_account:[{
        show:false
      }],
      userid:'WangShiXia',
      user:{},
      department:{
        show:false,
        options:{},
      },
      
  
      paymentForm:{
        no:'S202106150001',
        title:'',
        desc:'',
        contract_no:'',
        contract_url:'',
        user_name:'',
        department:'',
        position:'',
        payer:'',
        payee:'',
        bank:'',
        bank_account:'',
        amount:'',
        payment_term:'',
        costs:[
          {
            cost_center:'',
            cost_center_id:'',
            cost_account:'',
            cost_account_id:'',
            amount:''
          }
        ]
      },
      approverForm:{
        department_id:'',
        costs:[],


      },
      approvers:{},

    }
  },
  created(){
    document.title = '付款申请单'
    this.getCostCenter()
    this.getCostAccount()
    this.getApprover()
    this.getUserInfo();
  },
  methods:{
    getUserInfo(){
      this.$http.post('user_info',{userid:this.userid})
      .then((res)=>{
        console.log(res.data.code)
        if(res.data.code !=200){
          console.log(res.data.msg)
        }
        this.user = res.data.data
        this.paymentForm.position = this.user.position
        this.paymentForm.user_name = this.user.name
        this.department.options = this.user.departments
        
      })

    },
    getCostCenter(){
      this.$http.post('getcostcenter')
      .then((res)=>{
        console.log(res)
        if(res.data.code !=200){
          console.log(res.data.msg)
        }
        this.cost_centers = res.data.data;
      })

    },
    getCostAccount(){
      this.$http.post('getcostaccount')
      .then((res)=>{
        console.log(res)
        if(res.data.code !=200){
          console.log(res.data.msg)
          return false;
        }
        this.cost_accounts = res.data.data;
      })
    },
    //获取审批人
    getApprover(){
      this.$http.post('getapprover',this.approverForm)
      .then((res)=>{
        console.log(res)
        this.approvers = res.data.data;
        console.log(this.approvers)
      })
      

    },

    payment_term_onselect(value){
      this.payment_term.show = false
      this.paymentForm.payment_term = value
    },
    payer_onselect(item){
      this.payer_show = false;
      this.paymentForm.payer = item.id;

    },
    department_onselect(item){
      this.department.show = false;
      this.paymentForm.department = item.name;
      // this.payment.department = item.name;
      this.approverForm.department_id = item.Id;
      this.getApprover();

    },
    addNode(){
      this.paymentForm.costs.push({cost_center:'', cost_account:'',amount:''})
      this.cost_payer.push({show:false})
      this.cost_account.push({show:false})

    },
    delNode(i){
      if(this.paymentForm.costs.length == 1){
        // this.$alert('费用归集及分摊至少保留一个！')
        // this.$dialog.alert({
        //   message: '费用归集及分摊至少保留一个！',
        // })
        this.$toast.fail('费用归集及分摊至少保留一个！');
        return false;
      }
      this.paymentForm.costs.splice(i,1)

    },
    cost_payer_onselect(item,i){

      console.log(i)
      this.cost_payer[i].show = false;
      this.paymentForm.costs[i].cost_center = item.name;

    },
    cost_account_onselect(item,i){

      console.log(i)
      this.cost_account[i].show = false;
      this.paymentForm.costs[i].cost_account = item.name;

    },
    payment_submit(){
      console.log(this.paymentForm)
      // this.$http.post('payment',this.paymentForm){

      // }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.payment{
  background-color: #eee;
}
.title-style{
  font-size:26px;
}
.account_top{
  border-left: #42b983 5px solid;
  height:45px;
  border-bottom:#eee 1px solid;
}
.account_top .no{
  margin:10px 5px;
  font-weight: bold;
  color:#42b983;
  float:left;
  padding-top:5px;

}
.account_top button{
  margin:10px 5px;
  color:#42b983;
  font-weight: bold;

}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
