<template>

  <div class="home">
  
    <van-row class="payment">
      <van-col span="24">
        <van-divider :style="{ color: '#ffffff', borderColor: '#ffffff', padding: '0 10px' }">
          集团
        </van-divider>
        <van-grid :column-num="4">
          <van-grid-item icon="shopping-cart-o" text="采购" to="/payment"/>
          <van-grid-item icon="wap-home-o" text="装修设备房租" />
          <van-grid-item icon="balance-o" text="工资薪酬" />
          <van-grid-item icon="cashier-o" text="行政费用" />
          
          <van-grid-item icon="bullhorn-o" text="营销费用" />
          <van-grid-item icon="after-sale" text="融资类支付" />
          <van-grid-item icon="after-sale" text="税金" />
          <van-grid-item icon="refund-o" text="资金调拨" />
          <van-grid-item icon="cash-on-deliver" text="员工借款" />
          <van-grid-item icon="cash-on-deliver" text="日常报销" />
          <van-grid-item  icon="cash-on-deliver" text="其它款项支付" />
          <van-grid-item  />
        </van-grid>
        

      </van-col>
      <van-col span="24">
        <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 10px' }">
          分公司
        </van-divider>
        <van-grid :column-num="4">
          <van-grid-item icon="bullhorn-o" text="营销费用" />
          <van-grid-item icon="wap-home-o" text="装修设备房租" />
          <van-grid-item icon="balance-o" text="工资薪酬" />
          <van-grid-item icon="cashier-o" text="行政费用" />

          <van-grid-item icon="cash-on-deliver" text="员工借款" />
          <van-grid-item icon="cash-on-deliver" text="日常报销" />
          <van-grid-item  icon="cash-on-deliver" text="其它款项支付" />
          <van-grid-item   />
          <!-- <van-grid-item icon="shopping-cart-o" text="材料采购" to="/payment"/>
          <van-grid-item icon="wap-home-o" text="房租" />
          <van-grid-item icon="cashier-o" text="行政费用" />
          <van-grid-item icon="balance-o" text="工资薪酬" />
          <van-grid-item icon="bullhorn-o" text="营销费用" />
          <van-grid-item icon="after-sale" text="融资类支付" />
          <van-grid-item icon="cash-on-deliver" text="员工借款" />
          <van-grid-item icon="refund-o" text="资金调拨" />
          <van-grid-item  /> -->
        </van-grid>
        

      </van-col>
    </van-row>
    <!-- <van-row class="payment">
      
    </van-row> -->

    <Footer></Footer>   
  </div>

  
  

</template>

<script>
// @ is an alias to /src
import Footer from './Footer.vue'

export default {
  name: 'Home',
  components: {
    Footer
  },
  data(){
    return{
      code:'',
      local:window.location.href,
      access_token:'',
      user_info:{},
      approve:{
        access_token:'',
        creator_userid:'WangShiXia',
        template_id:'BsAcWRPggRGBku5Xn9iWbx1W1ANqfopT7jdcEbeTV',
        use_template_approver:1,
        apply_data: {
        contents: [
                {
                    "control": "Text",
                    "id": "Text-1623333717726",
                    "value": {
                        "text": "555555555555"
                    }
                }
            ]
    },

      }

    }
  },
  created(){
    // this.getCode();
    // console.log(this.$route.query)
    document.title = 'KEEN'

  },
  methods:{
    getCode(){
      this.code=this.$route.query.code;
      //var code = getUrlParam('code');// 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      console.log(this.code);
      if (this.code == null || this.code == ''||this.code == "undefined") {
        console.log(3)
        //window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+APPID+'&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect';
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww808a9eef1ee311b9&redirect_uri='+encodeURIComponent(this.local)+'&response_type=code&scope=snsapi_base&state=123#wechat_redirect';
        return false;
      } else {
        // this.getAccessToken(); //把code传给后台获取用户信息
        return false;
      }
    },
    getAccessToken(){
      this.$http.post('https://admin.aomengqixiao.com/getaccesstoken')
      .then((res)=>{
        console.log(res)
        console.log(this.access_token)
        this.access_token = res.data.access_token
        this.approve.access_token = res.data.access_token
        
        this.getUserInfo();
      })

    },
    getUserInfo(){
      this.$http.post('https://admin.aomengqixiao.com/getuserinfo',{'access_token':this.access_token,'code':this.code})
      // this.$http.get('https://qyapi.weixin.qq.com/cgi-bin/user/getuserinfo?access_token='+this.access_token+'&code='+this.code)
      .then((res)=>{
        console.log(res)
        this.user_info = res.data
        this.approve.creator_userid= res.data.UserId
      })


    },
    onSubmit(){
      this.$http.post('https://admin.aomengqixiao.com/applyevent',this.approve)
      .then((res)=>{
        console.log(res)
      })
    }

  }
}
</script>
<style scoped>

.home{
  
  background:  url(/images/home_bg.jpg) repeat-x ;
  

}
.payment{
  margin:0px 10px 100px 10px;
  padding-top:10px;
}
</style>
