<template>
  <div>
    <van-cell-group title="审批人" :border="true" size="large">
      <van-steps direction="vertical">

      <!-- <van-step v-for="(items,index) in approvers" :key="index"> -->
      <div v-for="(items,index) in approvers" :key="index" >
        <div class="approvers">
          <div class='approver'  v-for="approver in items" :key="approver.id">
          <van-image
            round
            width="3rem"
            height="3rem"
            :src="approver.thumb_avatar"
          />
          <h3>{{approver.name}}</h3>
          <p>{{approver.position}}</p>
        </div>
        
        </div>
        

      </div>
        <!-- <van-divider>文字</van-divider> -->
      <!-- </van-step> -->
      
    </van-steps>
  </van-cell-group>
    
  </div>
</template>

<script>
export default {
  name: 'Approver',
  props: {
    approvers:Object,

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.approvers{
  text-align: 0 auto;
  /* margin:10px; */
}
.approver{
  float:left;
  display:inline;
  margin:10px 15px;

}
.van-steps--vertical{

}
.approver .van-step{
  display:block;

}
h3 {
  font-size:12px;
  margin:5px;
}
p{
  margin:0px;
  font-size:12px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
