import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import axios from 'axios'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);


Vue.prototype.$http = axios
Vue.use(ElementUI)
axios.defaults.baseURL = 'http://api.keen.com/';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// if(window.localStorage.zywxtoken){
  
// }
axios.interceptors.request.use((config)=>{
  config.headers.token = window.localStorage.token;
  console.log(config);
  return config;
})


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
