<template>
  <div>
    <van-row class='bottom'>
      <van-col span="24">
        <van-grid :column-num="3">
        
          <van-grid-item icon="add-o" text="新申请" />
          <van-grid-item icon="manager-o" text="我审批的" />
          <van-grid-item icon="upgrade" text="已提交" />
        </van-grid>
      </van-col>
      
    </van-row>

  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.bottom{
  position:fixed;
  bottom:30px;
  width:100%;
  height:60px; 
  z-index:99; 
}
</style>
